import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  PageWrapper,
  Loader,
  PageContainer,
  PreviousPage,
  AccountDetails,
  AccountInfo,
  PageFooter,
  AccountLinks,
  AccountImpacts,
} from 'src/components'
import { useAccount, useAccountImpacts, useAccountProducts, useGetAccountSimpleId } from 'src/hooks'
import { InfoWrapper } from './account.styled'
import { TopDivider } from '../product/product.styled'
import { LayoutContainer } from 'src/components/layout-container'
import { useEffect } from 'react'
import { uselAccountFirstRedirect } from 'src/contexts'
import { Box, Divider } from '@mui/material'
import { setAccountMetadata } from 'src/utils'

export default function Account() {
  const [, i18n] = useTranslation()
  const { accountId } = useParams()
  const navigate = useNavigate()

  const { setIsRedirected } = uselAccountFirstRedirect()

  const { data: accountImpacts, isLoading: isAccountImpactsLoading } = useAccountImpacts(
    i18n.language,
    accountId,
  )

  useEffect(() => {
    setIsRedirected(true)
  }, [])

  const location = useLocation()

  const isUuid = (id: string) => {
    const uuidRegex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
    return uuidRegex.test(id)
  }

  const { data: accountSimpleId, isLoading: accountSimpleIdIsLoading } = useGetAccountSimpleId(
    isUuid(accountId) ? accountId : null,
  )

  const { data: account, isLoading: isAccountLoading } = useAccount(accountId, i18n.language)

  const withourProduct = !location.state?.productId
  const { data: products, isLoading } = useAccountProducts(accountId, withourProduct)

  useEffect(() => {
    if (isUuid(accountId)) {
      if (!accountSimpleIdIsLoading && accountSimpleId) {
        navigate(location.pathname.replace(accountId, accountSimpleId), { replace: true })
      }
    }
  }, [accountSimpleIdIsLoading])

  useEffect(() => {
    if (account) {
      setAccountMetadata({
        title: `Trackgood Journey - ${account.account_holder}`,
        account: {
          account_holder: account.account_holder,
          country: account.country,
          region: account.region,
          description: account.description,
        },
      })
    }
  }, [isAccountLoading, account])

  if (isAccountLoading || !account || isLoading || isAccountImpactsLoading) {
    return <Loader />
  }

  const otherProducts = location.state?.productId
    ? products?.products.filter((p) => p.simple_id.toString() !== location.state?.productId)
    : products?.products

  const currentProduct = location.state?.productId
    ? products?.products.find((p) => p.simple_id.toString() === location.state?.productId)
    : null

  const allProducts = currentProduct ? [currentProduct, ...otherProducts] : products.products

  const filteredAccountImpacts = accountImpacts.filter((item) => item.account_page_show === true)

  return (
    <LayoutContainer>
      {account?.alternative_color && <TopDivider bgColor={account.alternative_color} />}
      <PageWrapper>
        {/* <MapProvider>
        <AboutCardV2 />
      </MapProvider> */}

        <PageContainer>
          {/* <PageWrapperInner> */}
          {location.state?.from && <PreviousPage to={location.state?.from} />}
          {/* <TitleSt variant="h1">{t('account-header-title')}</TitleSt> */}
          <InfoWrapper>
            <AccountInfo
              image={account.image_medium || account.image}
              location={`${account.country}, ${account.region}`}
              accountName={account.account_holder}
              walletAddress={account.wallet_address}
              showWalletAddress={account.show_wallet_address}
            />
          </InfoWrapper>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: '28px' }}>
            <AccountLinks
              socials={account.socials || {}}
              website={account.website}
              email={account.email}
            />
          </Box>
          {filteredAccountImpacts.length > 0 && (
            <>
              <Divider sx={{ my: '20px' }} />
              <AccountImpacts impacts={filteredAccountImpacts} />
              <Divider sx={{ mt: '25px', mb: '28px' }} />
            </>
          )}

          <AccountDetails
            media={account.media}
            allProducts={allProducts}
            description={account.description}
          />
          {/* </PageWrapperInner> */}
        </PageContainer>
      </PageWrapper>
      <PageFooter accountId={accountId} />
    </LayoutContainer>
  )
}
