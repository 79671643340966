export const setProductMetadata = ({ title, product }) => {
  // Set the page title
  if (title) {
    document.title = title
  }

  // Create the meta description content
  if (product) {
    const descriptionContent = `Discover ${product.name} on Trackgood Journey.`

    const metaDescription = document.querySelector('meta[name="description"]')

    if (metaDescription) {
      metaDescription.setAttribute('content', descriptionContent)
    } else {
      const newMetaDescription = document.createElement('meta')
      newMetaDescription.name = 'description'
      newMetaDescription.content = descriptionContent
      document.head.appendChild(newMetaDescription)
    }
  }
}

export const setAccountMetadata = ({ title, account }) => {
  // Set the page title
  if (title) {
    document.title = title
  }

  if (account) {
    // Construct the meta description
    const descriptionContent = `Explore the journey of ${account.account_holder}. Discover their products and impacts on Trackgood Journey.`

    // Check if a meta description already exists
    const metaDescription = document.querySelector('meta[name="description"]')

    if (metaDescription) {
      // Update the existing meta description
      metaDescription.setAttribute('content', descriptionContent)
    } else {
      // Create a new meta description tag
      const newMetaDescription = document.createElement('meta')
      newMetaDescription.name = 'description'
      newMetaDescription.content = descriptionContent
      document.head.appendChild(newMetaDescription)
    }
  }
}
