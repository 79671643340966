import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material'
// import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { useTranslation } from 'react-i18next'
import { lighten } from '@mui/material/styles'

import { useAccount, useAuthUser, useMe, useProduct } from 'src/hooks'
import { MAIN_APP_URL } from 'src/constants'
import PlusIcon from 'src/assets/icons/v2/PlusIcon'
import { EUserRoles } from 'src/types'

import { FabSt } from './scan-fab.styled'

export const ScanFAB = () => {
  // const [t] = useTranslation()
  const { data: authUser } = useAuthUser()
  const { data: me } = useMe()
  const { productId, simpleProductId } = useParams()
  const theme = useTheme()

  const [, i18n] = useTranslation()
  const { data: product } = useProduct(productId || simpleProductId, i18n.language)
  const { data: account } = useAccount(product?.account_id, i18n.language)

  if (!product || !account) {
    return null
  }

  const handleNavigate = () => {
    if (!authUser || (!product && !simpleProductId) || !product) {
      return
    }
    window.open(`${MAIN_APP_URL}scans/create?productId=${product.id}`, '_self')
  }

  const isSuperadmin = authUser?.roles?.includes(EUserRoles.superadmin)
  if (!authUser || !me || (!isSuperadmin && !me.accountRoles)) {
    return null
  }

  return (
    <FabSt
      variant="extended"
      size="large"
      onClick={handleNavigate}
      sx={{
        backgroundColor: account.primary_color ? account.primary_color : theme.palette.primary.main,
        zIndex: 10,
        '&:hover': {
          backgroundColor: account.primary_color
            ? lighten(account.primary_color, 0.2)
            : lighten(theme.palette.primary.main, 0.2),
        },
      }}
    >
      <PlusIcon size="28" />
      {/* <CameraAltIcon sx={{ height: '18px', width: '18px' }} /> */}
      {/* {t('scan-button-text')} */}
    </FabSt>
  )
}
